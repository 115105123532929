@import "../../styles/dynamic/all.scss";

.cr-footer {
  background-color: #f0f0f0;

  .navbar {
    width: 100%;
    padding: 0px;

    .navbar-nav {
      width: 100%;

      .nav-item {
        cursor: pointer;
        height: 50px;
        width: 100%;
        padding-top: 20px;
        font-weight: 700;
        font-size: 0.8rem;
        position: relative;
        padding-left: 20px;
        color: #2c2c2c;

        svg {
          float: right;
          margin-right: 15px;
          margin-top: -4px;
        }

        &::after {
          content: " ";
          left: 15px;
          position: absolute;
          bottom: 0px;
          background-color: #e4e4e4;
          height: 1px;
          width: calc(100% - 30px);
        }

        &.sub-footer {
          padding-left: 40px;
          a {
            all: unset;
          }
        }
      }
    }
  }

  .media {
    text-align: center;
    padding: 24px 0 22px;
    span {
      color: #2C2C2C;
      font-size: 0.875rem;
      line-height: 16px;
      font-weight: 600;
    }
    .logo {
      padding-top: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 36px;
        height: auto;
        margin: 0 8px;
        cursor: pointer;
      }
    }
  }
  .copyright {
    background-color: #E4E4E4;
    text-align: center;
    padding: 23px 0;

    p {
      font-size: 	0.625rem;
      font-weight: 400;
      line-height: 18px;
      padding-bottom: 18px;
    }

    img {
      margin: 0 8px;
      cursor: pointer;
    }
  }
}
