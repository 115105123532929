.iss-login-header {
  height: 100px;
}

.iss-login-header img {
  width: 270px;
  height: 31px;
  margin-top: 30px;
  padding-left: 30px;
}

.iss-login-footer {
  height: 50px;
}
.iss-login-bg {
  height: calc(100vh - 150px);
  background-size: cover;
}

.iss-login-layer {
  width: 100vw;
  height: 100%;
  transform: skew(-20deg);
  background: #d4133f;
  position: absolute;
  left: 60vw;
  opacity: 0.6;
}

.iss-login-form-wrapper-right {
  width: 40vw;
  position: absolute;
  right: 0px;
  height: 100%;
}

.iss-login-form-wrapper-center {
  width: 60%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.iss-login-title {
  font-family: "gt-walsheim";
  color: white;
  font-size: 2.3rem;
  font-weight: 900;
  padding: 10px;
  border-bottom: 2px solid white;
  margin-bottom: 30px;

  .ssoLoginButton {
    position: relative;
    display: inline-block;
    float: right;
    cursor: pointer;

    span {
      position: absolute;
      font-size: 0.5rem;
      display: inline-block;
      top: 0px;
      right: 10px;
    }
  }
}

.iss-login-field-wrapper {
  background: rgba(250, 250, 250, 0.22);
  border-radius: 5px;
  width: 90%;
  margin: auto;
  margin-bottom: 20px;
}
.iss-login-field-wrapper img {
  width: 25px;
  height: 28px;
  margin: 15px 5px 15px 25px;
}
.iss-login-field-wrapper input {
  width: calc(100% - 75px);
  display: inline;
  border: none;
  background: none;
  color: white;
  font-size: 18px;
  font-family: "gt-walsheim";
}
.iss-login-field-wrapper input:focus {
  border: none;
  background: none;
  outline: none;
  color: white;
}
.iss-login-button {
  background-color: #e1001f;
  width: 91%;
  color: white;
  font-size: 20px;
  font-family: "gt-walsheim";
  font-weight: bold;
  height: 45px;
  border: none;
  border-radius: 5px;
  box-shadow: 15px;
  position: absolute;
  left: 4.5%;
}

.iss-login-field-wrapper input::placeholder {
  color: white;
  opacity: 0.63;
  font-size: 18px;
} /* Chrome, Firefox, Opera, Safari 10.1+ */
.iss-login-field-wrapper input::-ms-input-placeholder {
  color: white;
  opacity: 0.63;
  font-size: 18px;
} /* Microsoft Edge */
.iss-login-field-wrapper input:-ms-input-placeholder {
  color: white;
  opacity: 0.63;
  font-size: 18px;
} /* Internet Explorer 10-11 */
.iss-login-field-wrapper input::-webkit-input-placeholder {
  color: white;
  opacity: 0.63;
  font-size: 18px;
}
.iss-login-field-wrapper input::-moz-placeholder {
  color: white;
  opacity: 0.63;
  font-size: 18px;
}

.iss-login-error {
  font-family: "gt-walsheim";
  color: #d4281c;
  height: 35px;
  vertical-align: middle;
  background-color: cornsilk;
  padding: 5px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.iss-login-error span {
  font-weight: bold;
}
