// page
.cr-page {
  min-height: calc(100vh - 55px);
  margin-top: 50px;
  /*margin-bottom: 80vh;*/
  margin-bottom: 40%;
  width: 100%;

  &.special {
    margin-top: 50px;
  }

  &__header {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
    }
    display: flex;
    align-items: flex-end;
    align-self: center;
  }

  &__title {
    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }
    padding-right: 0.5rem;
    margin-bottom: 1rem;
    font-size: 30pt;
    color: #666666;
    font-family: "gt-walsheim";
    font-weight: bold;
  }

  &__breadcrumb {
    @include media-breakpoint-down(sm) {
      align-self: flex-start;
    }
    align-self: flex-end;
    padding: 0;
    text-transform: capitalize;
    background: theme-color("light");
  }

  .row {
    // stylelint-disable-next-line
    .col,
    > [class^="col-"] {
      margin-bottom: 1rem;
    }
  }
}

.cr-page-spinner {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba($color: #000000, $alpha: 0.4);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iss-page-bg {
  background-color: #f6f6f6;
  overflow-x: visible !important;
}
