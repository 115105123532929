.frame_main_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .frame_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}