input[type='checkbox'] {
  width: 20px;
  height: 20px;
  background-color: #fff;
  -webkit-appearance: none;
  border: 1px solid #2c2c2c;
  vertical-align: middle;
  border-radius: 2px;
  outline: none;
  &:disabled {
    border: 1px solid #707070;
    background: rgba(255, 255, 255, 0.267);
  }
}

input[type='checkbox']:checked {
  background: #2c2c2c url('../../assets/img/icons/Right.png') no-repeat center;
}

input[type="checkbox"]:disabled {
  border: 1px solid #BABABA;
  background: #BABABA;
  cursor: not-allowed;
}
