// override bootstrap 4 variables

$theme-colors: (
  "primary": #6a82fb,
  "secondary": #fc5c7d,
  "success": #7cd89c,
  //#45b649,
  "danger": #d0281b,
  //#f85032,
  "warning": #ffda83,
  //#ffd700,
  "info": #00c9ff,
);

$enable-gradients: true;

// card
//
$card-cap-bg: #fff;

// sidebar
$cr-sidebar-width: 264px;

// Button colors
$btn-ok: #d4281c;
$btn-cancel: #d4281c;
$btn-disabled: #e0e0e0;
$btn-text: #fff;

// React - Notification
$react-notification-text: #fff;

// General
$text-color-red: #d0281b;

// For Amway
$amway-dark-blue: #002f5f;
$amway-blue: #38539a;
$amway-white: #ffffff;
$amway-black: #2c2c2c;