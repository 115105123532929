@import "../../styles/dynamic/media_queries.scss";
@import "../../styles/dynamic/colors.scss";
@import "../../styles/dynamic/all.scss";

.container {
  background-color: rgba(112, 112, 112, 0.7);
}

.paper_container {
  overflow-y: auto !important;
}

//iOS container - Fixed iOS 13 & below's issues - no pop up
.paper_container_iOS {
  overflow-y: visible !important;
}
