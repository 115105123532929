@import "variables";

// libraries
@import "~bootstrap/scss/bootstrap.scss";
@import "~react-infinite-calendar/styles";

// base
@import "base";
@import "overrides";
@import "extends";
@import "animations";
@import "utils/utils";
@import "isr_mixins";

// components
@import "../framework-layout/styles/index.scss";
@import "components/widget";

// themes
@import "themes/colors";
