.form_container {
  top: 50px;

  .label_accesstoken {
    width: 100%;
  }

  .label_idtoken {
    width: 100%;
  }

  .input_accesstoken {
    width: 100%;
  }
  
  .input_idtoken {
    width: 100%;
  }
}
