/* New style */
.isr-button-std {
  background: none;
  color: $btn-text;
  font-size: 1.1rem;
  font-weight: bold;
  height: 45px;
  border: none;
  border-radius: 5px;
  box-shadow: 15px;
  padding-left: 20px;
  padding-right: 20px;

  &:active,
  &:focus {
    border: none !important;
    box-shadow: none !important;
    outline: none;
  }

  &.ok {
    background-color: $btn-ok !important;
  }

  &.cancel {
    background-color: $btn-cancel !important;
  }

  &.disabled {
    background-color: $btn-disabled !important;
    cursor: default !important;
    pointer-events: none !important;
  }
}

.isr-button-small {
  background: none;
  color: $btn-text;
  font-size: 0.8rem;
  font-weight: 500;
  height: 25px;
  border: none;
  border-radius: 5px;
  box-shadow: 15px;
  padding-left: 10px;
  padding-right: 10px;

  &:active,
  &:hover {
    border: none !important;
    box-shadow: none !important;
  }

  &.ok {
    background-color: $btn-ok !important;
  }

  &.cancel {
    background-color: $btn-cancel !important;
  }

  &.disabled {
    background-color: $btn-disabled !important;
  }
}

/* Old style */

.iss-button-red {
  background: none;
  background-color: $btn-ok !important;
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  min-height: 42px;
  border: none;
  border-radius: 5px;
  box-shadow: 15px;
  padding-left: 20px;
  padding-right: 20px;
  white-space: nowrap;
}
.iss-button-red:hover,
.iss-button-red:active {
  background: #af2116;
  background-color: #af2116 !important;
}
.iss-button-red:active {
  border: none !important;
  box-shadow: none !important;
}
.iss-button-grey {
  background: none;
  background-color: $btn-disabled !important;
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  min-height: 42px;
  border: none;
  border-radius: 5px;
  box-shadow: 15px;
  padding-left: 20px;
  padding-right: 20px;
  white-space: nowrap;
  &.grey {
    background-color: gray !important;
  }
}
.iss-button-grey:active,
.iss-button-grey:focus,
.iss-button-red:active,
.iss-button-red:focus {
  border: none !important;
  box-shadow: none !important;
  outline: none;
}

.iss-button-small,
.iss-button-small:hover,
.iss-button-small:active {
  background: none;
  color: white;
  font-size: 0.8rem;
  font-weight: 500;
  height: 25px;
  border: none;
  border-radius: 5px;
  box-shadow: 15px;
  padding-left: 10px;
  padding-right: 10px;

  &.red {
    background-color: #d4281c !important;
  }

  &.green {
    background-color: #7cd89c !important;
  }

  &.grey {
    background-color: gray !important;
  }
}

.iss-button-small:active {
  border: none !important;
  box-shadow: none !important;
}
